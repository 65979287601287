import React from 'react';
import { createTheme, alpha } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { grey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    darker: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    dark: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    darker: Palette['primary'];
    dark: Palette['primary'];
  }
  interface PaletteOptions {
    darker: PaletteOptions['primary'];
    dark: PaletteOptions['primary'];
  }
}

const FontSourceCodePro = ['Source Code Pro', 'sans-serif'].join(',');

const FontDinCondensed = ['DIN Condensed Demi', 'sans-serif'].join(',');

const INPUT_INDENT = 40;

const getTheme = (mode: PaletteMode) =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 968,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      mode,
      primary: {
        main: '#C58FFF',
        light: '#D9B7FF',
        dark: '#8964B2',
      },
      secondary: {
        main: '#DCFF02',
        light: '#E3FF34',
        dark: '#9AB201',
      },
      error: {
        main: '#FF8090',
        light: '#FF99A6',
        dark: '#B25964',
      },
      background: {
        default: mode === 'dark' ? '#000' : '#fff',
        paper: '#272727',
      },
      dark: {
        main: '#1B1B1B',
        light: grey[500],
        dark: grey[900],
        contrastText: '#fff',
      },
      darker: {
        main: '#272727',
        light: grey[500],
        dark: grey[900],
        contrastText: '#DCFF02',
      },
    },
    typography: {
      fontFamily: FontSourceCodePro,
      h1: {
        fontFamily: FontDinCondensed,
        fontSize: 80,
        fontWeight: 600,
        letterSpacing: 6.4,
        // lineHeight: '92px',
      },
      h2: {
        fontFamily: FontDinCondensed,
        fontSize: 50,
        fontWeight: 600,
        letterSpacing: 0.3,
        // lineHeight: '60px',
      },
      h3: {
        fontFamily: FontDinCondensed,
        fontSize: 40,
        letterSpacing: 0.1,
        // lineHeight: '46px',
      },
      h4: {
        fontFamily: FontDinCondensed,
        fontSize: 32,
        letterSpacing: 0.3,
        // lineHeight: '40px',
      },
      h5: {
        fontFamily: FontDinCondensed,
        fontSize: 26,
        letterSpacing: 0.2,
        // lineHeight: '28px',
      },
      h6: {
        fontFamily: FontDinCondensed,
        fontSize: 20,
        letterSpacing: 0.15,
        // lineHeight: '22px',
      },
      body1: {
        fontSize: 18,
        letterSpacing: -0.4,
        // lineHeight: '28px',
      },
      body2: {
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: -0.4,
      },
      subtitle1: {
        fontSize: 18,
        letterSpacing: -0.4,
        // lineHeight: '28px',
      },
      subtitle2: {
        fontSize: 16,
        letterSpacing: -0.4,
        // lineHeight: '28px',
      },
      caption: {
        fontSize: 14,
        letterSpacing: 0.4,
        // lineHeight: '22px',
      },
      overline: {
        fontSize: 12,
        letterSpacing: 1,
        // lineHeight: '18px',
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#000',
            backgroundImage: 'none',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            fontWeight: 500,
            lineHeight: '28px',
            textTransform: 'initial',
            boxShadow: 'none',

            '&:hover': {
              boxShadow: 'none',
            },
          },
          sizeMedium: ({ theme }) => ({
            padding: theme.spacing(2, 6),
            fontSize: 18,
          }),
          sizeSmall: ({ theme }) => ({
            padding: theme.spacing(1.5, 4),
            fontSize: 16,
            lineHeight: '24px',
          }),
          sizeLarge: () => ({
            fontSize: 20,
          }),
          outlined: ({ theme, ownerState }) => ({
            border: `1px solid ${alpha(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              theme.palette[ownerState.color].main,
              0.5
            )}`,
          }),
          contained: ({ theme, ownerState }) => ({
            '@media (hover: none)': {
              '&:active': {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                backgroundColor: theme.palette[ownerState.color].dark,
              },
            },
          }),
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            ...(['primary', 'secondary'].includes(
              ownerState.color as string
            ) && {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              backgroundColor: theme.palette[ownerState.color].main,
              color: theme.palette.common.black,

              '&:hover': {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                backgroundColor: theme.palette[ownerState.color].dark,
              },

              '@media (hover: none)': {
                '&:hover': {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  backgroundColor: theme.palette[ownerState.color].main,
                },

                '&:active': {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  backgroundColor: theme.palette[ownerState.color].dark,
                },
              },
            }),
          }),
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiSvgIcon-root': {
              fontSize: 30,
            },
            '&.Mui-disabled circle': {
              stroke: theme.palette.action.disabled,
            },
          }),
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            marginRight: theme.spacing(5),
          }),
          label: ({ theme }) => ({
            marginLeft: theme.spacing(2),
          }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: ({ theme }) => ({
            borderRadius: 0,
            color: theme.palette.common.black,
            fontWeight: 500,
            fontSize: 18,
            lineHeight: '28px',
            height: 28,

            '&::-webkit-scrollbar': {
              width: 10,
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.common.black,
            },

            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.primary.dark,
            },

            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
            },
          }),
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: ({ theme }) => ({
            padding: `21px ${INPUT_INDENT}px 13px ${INPUT_INDENT}px`,

            '& .MuiFilledInput-input': {
              paddingLeft: 0,
              paddingRight: 0,
            },
            '&.MuiInputBase-sizeSmall .MuiFilledInput-input': {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }),
          listbox: ({ theme }) => ({
            '.MuiAutocomplete-option': {
              padding: theme.spacing(2, `${INPUT_INDENT}px`),
            },
            '&::-webkit-scrollbar': {
              width: 10,
              padding: theme.spacing(0.5),
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.common.black,
            },

            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.primary.dark,
            },
          }),
          endAdornment: ({ theme }) => ({
            right: `37px !important`,
            fontSize: 24,
            pointerEvents: 'none',
          }),
          popupIndicator: ({ theme }) => ({
            color: theme.palette.common.black,
          }),
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            backgroundColor: theme.palette.primary.main,
            paddingLeft: ownerState.startAdornment
              ? `${INPUT_INDENT}px`
              : undefined,
            paddingRight: ownerState.endAdornment
              ? `${INPUT_INDENT}px`
              : undefined,
            transition: 'none',
            borderRadius: 0,
            color: theme.palette.common.black,

            '&.Mui-focused': {
              backgroundColor: theme.palette.primary.light,
            },

            '&:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled):hover': {
              backgroundColor: theme.palette.primary.main,
            },

            '&.Mui-error': {
              backgroundColor: theme.palette.error.main,
            },
          }),
          input: ({ theme, ownerState }) => ({
            padding: `28px ${INPUT_INDENT}px 20px ${INPUT_INDENT}px`,
            paddingLeft: ownerState.startAdornment
              ? theme.spacing(1)
              : undefined,
            paddingRight: ownerState.endAdornment
              ? theme.spacing(1)
              : undefined,

            '&.MuiInputBase-inputMultiline': {
              padding: '5px 24px 12px 26px',
            },
          }),
          sizeSmall: ({ theme, ownerState }) => ({
            input: {
              padding: `20px ${INPUT_INDENT}px 8px ${INPUT_INDENT}px`,
              paddingLeft: ownerState.startAdornment ? 1 : undefined,
              paddingRight: ownerState.endAdornment ? 1 : undefined,
            },
          }),
          inputSizeSmall: () => ({
            '&.MuiInputBase-inputMultiline': {
              padding: '5px 24px 0 26px',
            },
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            fontSize: 18,
            lineHeight: '28px',
            color: alpha(theme.palette.common.black, 0.5),
            transform: `translate(${INPUT_INDENT}px, ${
              ownerState.shrink ? '8px' : theme.spacing(3)
            }) scale(${ownerState.shrink ? 0.75 : 1})`,
            maxWidth: `calc(100% - ${ownerState.shrink ? 40 : 80}px)`,

            '&.Mui-focused': {
              color: alpha(theme.palette.common.black, 0.5),
            },

            '&.Mui-error': {
              color: alpha(theme.palette.common.black, 0.5),
            },
          }),
          sizeSmall: ({ ownerState }) => ({
            transform: `translate(${INPUT_INDENT}px, ${
              ownerState.shrink ? '2px' : '14px'
            }) scale(${ownerState.shrink ? 0.75 : 1})`,
          }),
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: ({ theme }) => ({
            ':not(.MuiInputAdornment-hiddenLabel)': {
              marginTop: `7px !important`,
            },
            '& > *': {
              color: `${theme.palette.common.black} !important`,
            },
          }),
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.black,

              '.MuiSvgIcon-root': {
                color: theme.palette.common.black,
              },
            },

            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.black,

              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
            },
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
            borderRadius: 0,
          },
        },
      },
      MuiChip: {
        defaultProps: {
          deleteIcon: <CloseIcon fontSize="small" />,
        },
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            borderRadius: 0,
            fontWeight: 500,
            fontSize: 14,
            ...(ownerState.variant === 'outlined' && {
              border: `1px solid ${alpha(theme.palette.grey[700], 0.5)}`,
            }),
            ...(ownerState.variant === 'outlined' &&
              ownerState.color !== 'default' && {
                border: `1px solid ${alpha(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  theme.palette[ownerState.color].main,
                  0.5
                )}`,
              }),
            ...(ownerState.color !== 'default' && {
              '& .MuiChip-avatar': {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                backgroundColor: theme.palette[ownerState.color].dark,
              },
            }),
            '& .MuiChip-deleteIcon': {
              fontSize: 16,
            },
            ...(ownerState.color === 'default' &&
              ownerState.variant === 'filled' && {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
                '& .MuiChip-avatar': {
                  backgroundColor: theme.palette.grey[400],
                  color: theme.palette.common.black,
                },
                '& .MuiChip-deleteIcon': {
                  color: alpha(theme.palette.common.black, 0.7),
                  fontSize: 16,

                  '&:hover': {
                    color: theme.palette.common.black,
                  },
                },
              }),
          }),
          sizeMedium: ({ theme }) => ({
            height: 40,

            '& .MuiChip-avatar': {
              marginLeft: theme.spacing(2),
              marginRight: -2,
            },
          }),
          sizeSmall: ({ theme }) => ({
            height: 31,

            '& .MuiChip-avatar': {
              marginLeft: theme.spacing(1),
            },
          }),
          labelMedium: ({ theme }) => ({
            padding: theme.spacing(0, 2),
          }),
          labelSmall: ({ theme }) => ({
            padding: theme.spacing(0, 1.5),
          }),
          deleteIconMedium: ({ theme }) => ({
            marginRight: theme.spacing(2),
            marginLeft: -3,
          }),
          deleteIconSmall: ({ theme }) => ({
            marginRight: theme.spacing(1.5),
          }),
        },
      },
      MuiSelect: {
        styleOverrides: {
          iconFilled: ({ theme }) => ({
            color: theme.palette.common.black,
            right: 40,
          }),
          select: () => ({
            paddingRight: `100px !important`,
            minWidth: 80,
          }),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: ({ theme }) => ({
            borderRadius: 0,
            fontSize: 14,
            backgroundColor: theme.palette.background.paper,
          }),
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        },
      },
      MuiTab: {
        styleOverrides: {
          root: ({ theme }) => ({
            textTransform: 'none',
            fontSize: 16,
          }),
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&:not(.active):not(.no-active-styles):not(.MuiButton-root)': {
              color: theme.palette.common.white,
              '&:hover': {
                color: theme.palette.secondary.main,
              },
            },
          }),
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            fontSize: 16,
            paddingLeft: INPUT_INDENT,
            paddingRight: INPUT_INDENT,
            margin: 0,
            backgroundColor: theme.palette.background.paper,
          }),
        },
      },
    },
  });

export default getTheme;
