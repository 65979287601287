import { useRouter } from 'next/router';

export const useToken = (): string => {
  const router = useRouter();
  const { token } = router.query;

  return token as string;
};

export default useToken;
