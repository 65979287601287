import React from 'react';
import Image from 'next/image';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import LogoFooter from './img/logo-footer.svg';

export const Footer: React.FC = () => (
  <Container maxWidth="md" sx={{ py: 3 }}>
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item>
        <Image src={LogoFooter as string} alt="Logo footer" />
      </Grid>
      <Grid item>
        <Typography color="textSecondary">
          {`copyright © ${new Date().getFullYear()} lemon.io, all rights reserved.`}
        </Typography>
      </Grid>
    </Grid>
  </Container>
);

export default Footer;
