import React from 'react';
import Image from 'next/image';

import useToken from 'Hooks/use-token';
import Routes from 'Config/routes';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Link from './link';
import Logo from './img/logo.svg';

export const Header: React.FC = () => {
  const token = useToken();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const href = `${Routes.Experience}/${token ?? ''}`;

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsOpen(open);
    };

  return (
    <AppBar position="static">
      <Container maxWidth="md">
        <Toolbar disableGutters sx={{ py: { xs: 1, sm: 2 } }}>
          <Box
            sx={{
              display: { xs: 'flex', sm: 'none' },
              position: 'relative',
              zIndex: 1,
            }}
          >
            <IconButton size="large" onClick={toggleDrawer(true)}>
              <MenuIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              flexGrow: { xs: 1, sm: 0 },
              justifyContent: { xs: 'center', sm: 'flex-start' },
              width: { xs: 138, sm: 195 },
              height: { xs: 38, sm: 50 },
            }}
            ml={{ xs: '-59px', sm: 0 }}
          >
            <Image src={Logo as string} alt="Logo" />
          </Box>
          <Link
            href={href}
            sx={{
              ml: 'auto',
              textDecoration: 'none',
              display: { xs: 'none', sm: 'flex' },
              fontWeight: 500,
            }}
            noActiveStyle={false}
          >
            my experience
          </Link>
          <Drawer
            anchor="left"
            open={isOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                minWidth: 0.65,
              },
            }}
          >
            <List>
              <ListItemButton
                component={Link}
                href={href}
                onClick={toggleDrawer(false)}
              >
                my experience
              </ListItemButton>
            </List>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
