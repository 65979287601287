import React from 'react';

type ColorModeContextType = {
  toggleColorMode: () => void;
};

export const ColorModeContext = React.createContext<ColorModeContextType>({
  toggleColorMode: () => {},
});

export const useColorModeContext = (): ColorModeContextType => {
  const context = React.useContext(ColorModeContext);

  if (!context) {
    throw new Error('Cannot be used outsite of ColorModeContext');
  }

  return context;
};
