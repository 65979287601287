import React from 'react';

import Header from 'Components/common/header';
import Footer from 'Components/common/footer';
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';

export const Layout: React.FC = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <Header />
    <Box component="main" sx={{ flex: 'auto', py: { xs: 3, sm: 4 } }}>
      <Container maxWidth="md">{children}</Container>
    </Box>
    <Footer />
  </Box>
);

export default Layout;
