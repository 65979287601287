import React from 'react';
import TagManager from 'react-gtm-module';

const useGtm = (gtmId: string): void => {
  React.useEffect(() => {
    TagManager.initialize({ gtmId });
  }, []);
};

export default useGtm;
