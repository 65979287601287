enum Routes {
  About = '/about',
  Experience = '/experience',
  AddProject = '/add-project',
  EditProject = '/edit-project',
  AddEducation = '/add-education',
  EditEducation = '/edit-education',
}

export default Routes;
