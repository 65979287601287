import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useGtm from 'Hooks/use-gtm';

import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '@ui-lib/create-emotion-cache';
import getTheme from '@ui-lib/theme';
import { ColorModeContext } from '@ui-lib/color-mode-context';
import { PaletteMode, responsiveFontSizes } from '@mui/material';
import '@ui-lib/font.css';

import Layout from 'Components/common/layout';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const theme = React.useMemo(
    () => responsiveFontSizes(getTheme(mode)),
    [mode]
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  useGtm('GTM-5XK5LVQ');

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Layout>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Component {...pageProps} />
          </Layout>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </CacheProvider>
  );
}

export default MyApp;
